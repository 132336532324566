import React, {useMemo} from 'react';
import Image from 'next/image';
import classNames from 'classnames';

const DynamicGrid = ({widgetData = {}}) => {
  const {Items, WebGrid, MobileGrid, WidgetAnalyticsName} = widgetData;
  const catalog = WidgetAnalyticsName === 'Kataloglar';

  const renderBottomText = useMemo(() => {
    return function RenderBottomText(BottomText, Url) {
      if (catalog) {
        if (Url === '2023') {
          return (
            <a href="/folders/2023_katalog.pdf" target="_blank" rel="noopener noreferrer">
              {BottomText}
            </a>
          );
        } else if (Url === '2024') {
          return (
            <a href="/2024_BoynerHediyeKatalogu/2024_BoynerHediyeKatalogu.html" target="_blank" rel="noopener noreferrer">
              {BottomText}
            </a>
          );
        } else if (Url === '2025') {
          return (
            <a href="/2025_BoynerHediyeKatalogu/2025_BoynerHediyeKatalogu.html" target="_blank" rel="noopener noreferrer">
              {BottomText}
            </a>
          );
        }
      } else {
        return <span>{BottomText}</span>;
      }
    };
  }, [catalog]);

  return (
    <div className="lg:w-[952px] flex flex-col items-center mx-auto py-[44px] lg:py-[64px]">
      <div className={`grid lg:grid-cols-${WebGrid} grid-cols-${MobileGrid} lg:gap-8 gap-x-2 gap-y-[42px] mx-auto rounded-[20px]`}>
        {Items?.map(({Image: valueImage, BottomText, Url}, index) => (
          <div key={index} className="rounded-[20px] px-4">
            {valueImage && (
              <Image src={valueImage} width={0} height={0} sizes="100vw" style={{width: '100%', height: 'auto'}} alt="image" loading="lazy" />
            )}
            <div>
              {BottomText && (
                <div
                  className={classNames(
                    'flex items-center mt-3 lg:mt-8 text-left text-[16px] lg:text-[24px] lg:text-xl lg:pl-0 font-boldie leading-[34px] lg:leading-normal tracking-[0.48px] lg:tracking-[0.64px] text-primary',
                    {
                      ['cursor-pointer']: catalog,
                    },
                  )}>
                  {renderBottomText(BottomText, Url)}
                  {catalog && <i className="icon text-shape-right text-active text-[12px] ml-3"></i>}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicGrid;
